let initialRegion =
  typeof window !== "undefined"
    ? localStorage.getItem("qvax-selected-region") || null
    : null

const regionReducer = (state = initialRegion, action) => {
  switch (action.type) {
    case "CHANGE_REGION":
      if (typeof window !== "undefined") {
        localStorage.setItem("qvax-selected-region", action.payload)
      }
      return action.payload
    default:
      return state
  }
}

export default regionReducer
