import langReducer from "./lang"
import { combineReducers } from "redux"
import regionReducer from "./region"
import sdkReducer from "./sdk"
import loginReducer from "./login"

const allReducers = combineReducers({
  lang: langReducer,
  region: regionReducer,
  sdk: sdkReducer,
  login: loginReducer,
})

export default allReducers
