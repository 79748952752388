let initialLang =
  typeof window !== "undefined"
    ? localStorage.getItem("qvax-selected-lang") || "nl"
    : "nl"

const langReducer = (state = initialLang, action) => {
  switch (action.type) {
    case "CHANGE_LANG":
      if (typeof window !== "undefined") {
        localStorage.setItem("qvax-selected-lang", action.payload)
      }
      return action.payload
    default:
      return state
  }
}

export default langReducer
