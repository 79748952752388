let initialState =
  typeof window !== "undefined"
    ? !!localStorage.getItem("qvax-session") || false
    : false

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOG_IN":
      return action.payload
    default:
      return state
  }
}

export default loginReducer
