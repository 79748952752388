import * as React from 'react';
import { Component } from 'react';
import { getSeatersClient } from 'seaters';
import MyContext from './myContext';
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';

class ContextProvider extends Component {
	state = {
		sdkClient: getSeatersClient({ apiPrefix: STRS_API_URL })
	};

	render() {
		return <MyContext.Provider value={this.state}>{this.props.children}</MyContext.Provider>;
	}
}

export default ContextProvider;
