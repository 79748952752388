import { getSeatersClient } from "seaters"

let initialSDK = getSeatersClient({
  apiPrefix: process.env.STRS_API_URL || "https://api.qa-seaters.com/api",
})

const sdkReducer = (state = initialSDK, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default sdkReducer
