let STRS_ENV = "development"
let FG_ID = "7d14b83d-42e6-48cc-b1ef-29b7839032b7"
let EMBEDDED_URL = "https://embedded.qa-seaters.com/seaters-embedded.bundle.js"
// let EMBEDDED_URL = "http://127.0.0.1:5500/dist/seaters-embedded.bundle.js"
let STRS_API_URL = "https://api.qa-seaters.com/api"
let EMBEDDED_URL_DOMAIN = "https://embedded.qa-seaters.com/";
// let EMBEDDED_URL_DOMAIN = "http://127.0.0.1:5500/dist/";

export { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL, EMBEDDED_URL_DOMAIN}



// import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../constants/global'