export const changeLang = lang => {
  return {
    type: "CHANGE_LANG",
    payload: lang,
  }
}

export const changeRegion = region => {
  return {
    type: "CHANGE_REGION",
    payload: region,
  }
}

export const logIn = login => {
  return {
    type: "LOG_IN",
    payload: login,
  }
}
